<template>
  <div class="container page-container login">
    <div class="row justify-content-md-center">
      <div class="col col-sm-12 col-lg-6">
        <h1>Inloggen</h1>
        <p>
          Log in alvorens je toegang krijgt tot je reserveringen, serienummers
          en andere informatie.
        </p>
        <div class="card">
          <div class="card-header bg-primary text-white">LOGIN</div>
          <div class="card-body">
            <form @submit.prevent="loginFormSubmit">
              <div class="form-group">
                <label>E-mailadres</label>
                <input
                  class="form-control"
                  id="input-1"
                  v-model="form.username"
                  type="text"
                  placeholder="E-mailadres"
                  required
                />
              </div>

              <div class="form-group">
                <label>Wachtwoord</label>
                <div class="input-group">
                  <input
                    class="form-control"
                    v-model="form.password"
                    placeholder="Wachtwoord"
                    required
                    :type="showPassword ? 'text' : 'password'"
                  />
                  <div class="input-group-append">
                    <div
                      class="btn btn-primary"
                      title="Wachtwoord tonen"
                      @click="showPasswordToggle()"
                    >
                      <font-awesome-icon v-if="!showPassword" fas icon="eye" />
                      <font-awesome-icon
                        v-if="showPassword"
                        fas
                        icon="eye-slash"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col col-xs-12 col-sm-6">
                  <button class="btn btn-primary mb-2" type="submit">
                    Inloggen
                    <font-awesome-icon
                      v-if="processing"
                      class="fa-spin"
                      far
                      :icon="['fad', 'spinner']"
                    />
                  </button>
                </div>
                <div class="col col-xs-12 col-sm-6">
                  <router-link
                    class="float-right"
                    :to="{ name: 'forgotPassword' }"
                    >Wachtwoord vergeten?</router-link
                  >
                </div>
              </div>
            </form>
            <hr v-if="error" />
            <div v-if="error" class="alert mb-0 alert-danger" show>
              {{ error }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from '@/services/AuthorizationService'

export default {
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      processing: false,
      error: false,
      showPassword: false
    }
  },
  metaInfo: {
    title: `Inloggen`
  },
  created: function() {
    if (this.$store.state.accounts[0]?.UserName) {
      this.$router.push({ path: '/account/user' })
    }
    if (this.$route.params.registered) {
      this.notify({ message: 'Account aanvraag succesvol' })
    }
  },
  methods: {
    notify({ message }) {
      this.$toasted.show(message)
    },
    async loginFormSubmit() {
      this.processing = true

      const result = await login({
        username: this.form.username,
        password: this.form.password
      })

      if (result) {
        this.error = result
      }

      this.processing = false
    },
    showPasswordToggle: function() {
      if (this.showPassword) {
        this.showPassword = false
      } else {
        this.showPassword = true
      }
    }
  }
}
</script>
<style lang="scss">
.login .card {
  border: 0px !important;
  box-shadow: 0 2px 4px 0 #0000001a;
  padding-bottom: 10px;
}
</style>
